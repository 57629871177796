.dg-tabs {
  display: flex;
  justify-content: center;
  background-color: $primary;
  padding: 30px;
  border-radius: 60px;
}

.dg-tabs li a {
  background-color: transparent;
  border: solid 2px #fff;
  border-radius: 30px;
  padding: 11px 11px;
  margin: 0px 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
}

.dg-tabs li a:hover,
.dg-tabs li a.active {
  background-color: #fff;
  color: $primary;
  text-decoration: none;
}

.tab-content{
  min-height: 590px;
}


@media only screen and (max-width: 1200px){
  .dg-tabs li a{
    font-size: 12px;
    padding: 11px 6px;
    margin: 0 8px;
  }
}

@media only screen and (max-width: 991px){
  .dg-tabs li a {
    padding: 11px 15px;
  }

  .dg-tabs li {
    margin: 20px 0px;
  }

  .dg-tabs{
    white-space: nowrap;
  }

  .nav-tabs{
    row-gap: 40px;
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: start;
  }
}

@media only screen and (max-width: 500px){
  .dg-tabs .nav-tabs {
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: start;

  }

  .dg-tabs{
    white-space: nowrap;
    padding: 10px 20px;
  }

  .dg-tabs li a{
    margin: 0px 8px;
  }
}