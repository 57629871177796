@keyframes TemplatesHero {
  0% {
    transform: perspective(75em) rotateX(16deg);
  }
  100% {
    transform: perspective(75em) rotateX(0deg);
  }
}

.templates {
  &__hero {
    transform: perspective(75em) rotateX(16deg);
    animation: 1s ease .5s 1 TemplatesHero forwards;
  }
}