.package_info_header:hover {
  cursor: pointer;
}

.info-text-small {
  font-size: 11px;
}

.collapsible-table.mt-5 {
  margin-top: 16px !important;
  overflow: hidden;
  transition: height 1s ease;

  .container {
    transition: height 1s ease;
  }

  div {
    transition: height 1s ease;
  }

  .collapsed {
    transition: height 1s ease;
    height: 73px;
  }
}

.pricing .col-md-3 {
  padding-left: 25px !important;
}

.pricing .d-none {
  display: flex !important;
}

@media screen and (max-width: 768px) {
  .hide-mob {
    display: none !important;
  }

  .hide-mob.d-flex {
    display: none !important;
  }

  .pakketbox h3 {
    font-size: 16px;
  }

  .onefifth {
    width: 24.7% !important;
    border-top: 1px solid #dee2e6 !important;
  }

  .hide-on-mobile{
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .hide-on-desktop{
    display: none !important;
  }
}
