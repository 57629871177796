// Global behaviour and functionality

html {
  scroll-behavior: smooth;
}

[id] {
  scroll-margin-top: clamp(5ex, 5vmax, 10ex); // Margin (not part of box model) when a link with a #section
}

// Clickable elements trigger pointer cursor
a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
option,
button {
  cursor: pointer;
}

// SVG divider was not fully covering on iPhone
.svg-divider-bottom {
  bottom: -4px;
}