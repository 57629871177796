.addon-item {

  & *:not(&__image) {
    transition: .15s ease;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-md;
  }

  &__image {
    box-shadow: 0 .125rem .5rem #c4cdd5;
    border-radius: 20%; //sharpen the borders in the corners
    max-height: 115px;
  }

  &:hover & {
    &__image {
      box-shadow: 0 .125rem 1.125rem #b6babf;
    }

    &__title {
      @extend .text-primary;
    }
  }
}