//
// Use this file to write your own custom SCSS.
//

@import "user/base/reset";
@import "user/base/typography";
@import "user/base/icons";

@import "user/components/buttons";
@import "user/components/logo";
@import "user/components/forms";
@import "user/components/counter";

@import "user/layout/header-nav";
@import "user/layout/footer";
@import "user/layout/sidebars";
@import "user/layout/color-sections";
@import "user/layout/doelgroep";

@import "user/pages/home";
@import "user/pages/addons";
@import "user/pages/templates";
@import "user/pages/prijzen-pakketten";
@import "user/pages/blog";

// Tijdelijke import, op mijn iPhone werd Poppins namelijk niet getoont /Andert
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

//
// Stylesheet breakdown according to the 7-1 pattern
// Check: https://sass-guidelin.es/#the-7-1-pattern
//