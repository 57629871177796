//inherit parent color class for svg icons
.svg-icon.currentColor,
.icon-circle.currentColor {
  svg:not([fill=none]),
  [fill]:not([fill=none]) {
    fill: currentColor !important;
  }

  svg [style*=stroke],
  svg [stroke]:not([stroke=none]) {
    stroke: currentColor !important;
  }
}
