.number-counter .icon-circle{
  width: 12rem;
  height: 12rem;
  flex-wrap: wrap;
}

.number-counter .icon-circle span,
.number-counter .icon-circle h2{
  text-align: center;
}

.number-counter .icon-circle h5{
  font-weight: 100;
}

.number-counter .counter-box{
  width: 100%;
  text-align: center;
}

.beoordeling-box{
  align-items: center;
}

.counter-lower .countup{
  font-size: 60px;
  display: block;
  width: 100%;
}

.countTrans{
  width:50% !important;
  float: left;
}

.countMln{
  width:50%;
  float: left;
  padding-top: 48px;
  font-weight: bold;
  font-size: 16px;
}

.counter-text-big{
  font-size: 80px;
}

.beoordeling-left{
  min-width: 182px;
}

@media only screen and (max-width: 800px) {
  .counter-section{
    padding: 15px !important;
  }

  .counter-text-big{
    font-size: 33px;
  }

  .beoordeling-box{
    padding-top: 25px;
  }

  .beoordeling-box h2{
    font-size: 14px !important;
  }

  .beoordeling-left{
    min-width: unset;
  }
}