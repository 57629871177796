.blogheader{
  background: #ffffffcf !important;
}

.blogintro{
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 70px;
}

.blogpost h4{
  margin-top: 50px;
}