// 
// Use this file to override any Bootstrap core and new theme variables.
//

// Color system

$primary:         #5954F8;
$primary-light:   #ebeafe; //new (OUD: #EFEEFB)
$primary-dark:    #120050; //new
$tertiary:        #12E8AE; //new
$tertiary-light:  #e3fcf5;
$success:         $tertiary;
$info:            #1AB6FF;
$warning:         #FFCC33;
$danger:          #B03E3E;
$pistachio:       $tertiary-light;
$purple:          $primary;
$dark:            $primary-dark;
$darkblue:        $primary-dark;


// Typography

$font-family-base:        'Poppins', Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
$headings-color:          $dark;

$font-weight-medium:      500;
$font-weight-semi-bold:   600;
$font-weight-bold:        700;
$font-weight-bolder:      bolder !important;

$display-font-weight:     700;
$lead-font-weight:        400;

$font-size-md: 1.125rem;


// Border-radius

$border-radius:             .375rem; //6px - was .25rem
$border-radius-sm:          .25rem; //4px - was .2rem
$border-radius-lg:          .5rem; //8px - was .3rem


// Filter drop-shadows

$drop-shadow-sm:        $box-shadow-sm;
$drop-shadow:           $box-shadow;
$drop-shadow-lg:        $box-shadow-lg;
$drop-shadow-light-sm:  $box-shadow-light-sm;
$drop-shadow-light:     $box-shadow-light;
$drop-shadow-light-lg:  $box-shadow-light-lg;


// Footer
$footer-dark-header-color:  #fff;