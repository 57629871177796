.sidebar--sticky {
  @extend .position-sticky;
  top: 2rem;
  @extend .mb-5;
}

// Sidebar on 'webshop-maken.html'
// Sidebar for vertical navigation menu
.features-sidebar {
  &__link {
    @extend .border-3;
    @extend .border-left;
    margin-left: -1px; // List item border now overlaps with ul border
    border-color: transparent !important;
    transition: .15s ease border-color;

    &:active,
    &:hover {
      @extend .border-primary;
    }
  }
}