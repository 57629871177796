.color-section {
  @extend .col-lg-11;
  @extend .col-xl-10;
  @extend .mx-auto;
  @extend .py-6;
  @extend .mt-4;
  @extend .mb-7;
  @extend .my-md-6;

  &__content {
    @extend .col-sm-10;
    @extend .col-lg-11;
    @extend .mx-auto;
  }

  &__title {
    @extend .text-primary;
    @extend .text-uppercase-bold-sm;
    @extend .mb-4;
  }

  &__heading {
    @extend .h3;
    @extend .w-xl-80;
    @extend .mb-4;
    font-weight: 700 !important;
  }

  &__media {
    @extend .mb-n8;
    @extend .mb-lg-0;

    &--overflow {
      @extend .vw-lg-50;
      @extend .vw-xl-40;

      @include media-breakpoint-up(lg) {
        @include max-width-is-half-container-width();
      }
    }
  }
}