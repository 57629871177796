// Navbar menu links
.nav-link {
  @extend .py-3; // More vertical spacing in the mobile menu
  @extend .py-lg-2;

  &--sub {
    @extend .mb-0;
  }

  &, &--sub {
    font-weight: $font-weight-medium;
  }
}

// No more collapsing menu on 'lg' breakpoint with smaller dimensions and sizes.
@include media-breakpoint-between(lg, xl) {
  .nav-link,
  .navbar-nav .btn {
    font-size: .825rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .6rem !important;
    padding-left: .6rem !important;
  }
}

@include media-breakpoint-between(xl, xxl) {

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
}

// Navbar dropdown hover icon styling

.navbar-nav {
  .dropdown-item .svg-icon svg {
    transition: transform .1s ease-in-out;
    transform: scale(1);
    will-change: transform;
  }

  .dropdown-item:hover .svg-icon svg {
    transform: scale(1.1);
    color: darken($primary, 7.5%); // Same hover style as buttons have
  }
}
