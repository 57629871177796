.btn {
  font-weight: $font-weight-medium; // fw 500 for all buttons for prominent buttons with a more bold look and feel
}

.btn-primary-light:hover {
  background-color: darken($primary-light, 7%) !important;
  border-color: darken($primary-light, 7%) !important;
}

.btn-dark:hover,
.btn-primary-dark:hover {
  background-color: darken($primary, 0%) !important;
  border-color: darken($primary, 0%) !important;
}