//
// Colors
//

// Create your own map
$custom-colors: (
        "primary-light": $primary-light,
        "primary-dark": $primary-dark,
        "tertiary": $tertiary,
        "tertiary-light": $tertiary-light
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);



//
// Typography
//

// Text hover color

.font-size-md {
  font-size: $font-size-md !important;
}

@each $color, $value in $theme-colors {
  .text-#{$color}-hover {
    &:hover,
    &focus {
      color: $value !important;
    }
  }
}

@each $color, $value in $grays {
  .text-gray-#{$color}-hover {
    &:hover,
    &focus {
      color: $value !important;
    }
  }
}

// Text font-weight
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}


//
// Micro Interactions
//

.hover-underline-slide {
  .parent &-child,
  & {
    background: linear-gradient(currentColor 0 0) var(--p, 0) 100% /var(--d, 0) 2px no-repeat !important;
    transition: 0.3s, background-position 0s 0.3s !important;
    text-decoration: none !important;
  }

  .parent:hover &-child,
  &:hover {
    --d: 100%;
    --p: 100%;
    text-decoration: none !important;
  }
}


//
// Shadows
//

.drop-shadow {

  filter: drop-shadow($drop-shadow) !important;

  &-sm {
    filter: drop-shadow($drop-shadow-sm) !important;
  }

  &-lg {
    filter: drop-shadow($drop-shadow-lg) !important;
  }

  &-light {
    filter: drop-shadow($drop-shadow-light) !important;

    &-sm {
      filter: drop-shadow($drop-shadow-light-sm) !important;
    }

    &-lg {
      filter: drop-shadow($drop-shadow-light-lg) !important;
    }
  }
}


//
// Overlay extension
//

.overlay-05:before {
  opacity: 0.05;
}

//
// Color divider
//

.divider-pastel:after {
  content: "'";
  background: #E3F6FF!important;
  height: 200px;
  position: absolute;
  width: 120%;
  bottom: -38px;
  left: -20px;
  transform: rotate(-3deg);
  z-index: -1;
}

.divider-tertiary:after {
  content: "'";
  background: #e3fcf5!important;
  height: 200px;
  position: absolute;
  width: 120%;
  bottom: -38px;
  left: -20px;
  transform: rotate(-3deg);
  z-index: -1;
}

.divider-primary:after {
  content: "'";
  background: #ebeafe!important;
  height: 200px;
  position: absolute;
  width: 120%;
  bottom: -38px;
  left: -20px;
  transform: rotate(-3deg);
  z-index: -1;
}

.divider-blue:after {
  content: "'";
  background: #0084de!important;
  height: 200px;
  position: absolute;
  width: 120%;
  bottom: -38px;
  left: -20px;
  transform: rotate(-3deg);
  z-index: -1;
}

.divider-blue-pos:after {
  content: "'";
  background: #00a3e2!important;
  height: 200px;
  position: absolute;
  width: 120%;
  bottom: -38px;
  left: -20px;
  transform: rotate(-3deg);
  z-index: -1;
}

.divider-sla-blue:after {
  content: "'";
  background: #bcd0fc!important;
  height: 200px;
  position: absolute;
  width: 120%;
  bottom: -38px;
  left: -20px;
  transform: rotate(-3deg);
  z-index: -1;
}

.backbone-form{
  width: 10px;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  background-color: #5857f9;
  float: left;
  margin-top: -135px;
}

.backbone-form:before {
  content: "";
  display: block;
  width: 20px;
  position: relative;
  right: 0;
  background-color: #19b6fe;
  height: 33%;
  bottom: 0;
}

.backbone-form:after {
  content: "";
  display: block;
  width: 20px;
  position: relative;
  right: 0;
  background-color: #13eaaf;
  height: 33%;
  bottom: 0;
}

.paper-form-demo{
  float: left;
  width: calc(100% - 25px);
  margin-top: -135px;
  background: #fff;
}

.paper-form-demo-simple{
  float: left;
  width: calc(100% - 25px);
  background: #fff;
}

.bg-darkblue-gradient  {
  background: linear-gradient(#120050, #5954f8) !important;
}

@media screen and (max-width: 992px) {
  .paper-form-demo,
  .backbone-form {
    margin-top: 0px;
  }
}

.paperFormPersonalDemoTitle{
   padding: 25px 0 0 25px;
  font-size: 1.5rem;
  z-index: 1;
  position: relative;
}

.paperFormPersonalDemoTitleSpan{
  color: #5756f8;
}

.paperFormPersonalDemoFooter{
  padding: 0 25px 10px 25px;
  font-size: 11px;
  z-index: 1;
  position: relative;
}

.paperFormForm{
  z-index: 0;
}

.bg-cover-top {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.klantcaselogo{
  height: 50px;
}