// Max-width is equal to the width of the current container
@mixin max-width-is-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

// Max-width is equal to HALF the width of the current container
@mixin max-width-is-half-container-width($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: calc( #{$container-max-width} / 2);
    }
  }
}