// Hero section background gradients
.hero-section {
  background: linear-gradient(150deg, rgba(255, 255, 255, 0) 0%, rgba(89, 84, 248, .2) 100%),
  linear-gradient(-70deg, rgba(235, 234, 254, 1) 0%, rgba(246, 249, 252, 1) 100%);
}


// Hero graphic with animation
$HeroGraphic-transform-animation-start: perspective(65em) rotateX(10deg) rotateY(-30deg) rotateZ(0deg);
$HeroGraphic-transform-animation-end: perspective(65em) rotateX(5deg) rotateY(-15deg) rotateZ(2deg);

@keyframes HeroGraphic {
  0% {
    transform: $HeroGraphic-transform-animation-start;
  }
  100% {
    transform: $HeroGraphic-transform-animation-end;
  }
}

.hero-graphic {
  transform: $HeroGraphic-transform-animation-start;
  animation: 2s ease .2s 1 HeroGraphic forwards;

  @media screen and (max-width: 991px) {
    & {
      position: relative;
      left: -5%;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    & {
      width: 60vw;
      margin-left: -5vw;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1499px) {
    & {
      width: 60vw;
      margin-left: -5vw;

      &__img {
        max-height: 800px;
      }

    }
  }

  @media screen and (min-width: 1500px) {
    & {
      width: 52vw;
    }
  }
}




// Review stars below cta button
.social-proof {
  @extend .font-size-sm;

  @media screen and (min-width: 768px) {
    & {
      font-size: 1rem !important;
    }
  }
}




// 'Trusted by' section
.trusted-by__logo {
  max-width: 128px;
  max-height: 60px;
}
