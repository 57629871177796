h1, h2, h3 {
  font-weight: $font-weight-bold;
}

a {
  transition: .1s;
  text-decoration-thickness: 3px !important;
  text-decoration-color: rgba(89,84,248,.5) !important;
  text-decoration-skip-ink: none;
  text-underline-offset: .04em !important;
}

@include media-breakpoint-only(xs) {
  .lead {
    font-size: 1.125rem;
  }
}

// Badge for categories and such
.badge-tag {
  @extend .badge;
  @extend .text-primary-dark;
  @extend .bg-primary-light;
}


.article {

  &__description {

    strong, b {
      font-weight: 600;
    }

    img {
      max-width: 100% !important;
      height: auto;
      @extend .my-3;
      @extend .shadow-sm;
    }

    hr {
      @extend .my-5;
    }
  }
}